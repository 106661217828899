import React, { Component } from 'react'
import Footer from '../../../../../../components/footer';
import GradientNav from '../../../../../../components/navbar/gradient';
import SubMenu from '../../../../../../components/navbar/submenu';
import TopBar from '../../../../../../components/navbar/topbar';
import I18n from '../../../../../../../i18n';
import '../../../masterDetail.scss';
import _ from 'lodash'
import OButton from '../../../../../../styled/components/button';
import { InnerWrapper, Wrapper } from '../../../../../../styled/components/wrappers';
import { Icon, Image, Responsive } from 'semantic-ui-react';
import FixedBanner from '../../../../../../components/fixedBanner';
import Config from '../../../../../../../config';

import './programDetail.scss'

export default class ProgramDetail extends Component {
    constructor(props) {
        super(props)

        this.state = {
         activeVideoPresentation: false
        }
    }

    setMetaItems = (types, level) => {

        let result = [`${I18n.t('practice.level')} ` +  I18n.t(`practice.#${level}`)];

        if (types.length>0) {

            _.each(_.pull(types, 'none'), p => result.push(I18n.t(`practice.${p}`)))

        }

        return (
            _.map(result, (item, index) => (
                <div key={index} className="related-item">
                    { item }
                </div>
            ))   
        )

    }

    practiceNow = sequenceId => {

        const { userConfig, sequences } = this.props;
        let selectedPractice = sequences[_.findIndex(sequences, ap => ap._id === sequenceId)];
        let sequence = window.btoa(selectedPractice.code + '/' + userConfig.practiceAudioLanguage + '/' + userConfig.practicePresentationType + '/' + true);
        this.props.history.push(`/practice/${sequence}`);

    };

    onTogglePreview = ()=> this.setState(prevState => ({ activeVideoPresentation: !prevState.activeVideoPresentation}))
    
    onMoreInfo = (e, practiceId) => {
        
        e.stopPropagation();
        this.props.history.push(`/practiceList/detail/${practiceId}`);
    
    }

    getTargetDescription = target => {

        if (target.includes('.')) {

            const targetGroup = target.substring(0, target.indexOf('.'));
            const targetSubGroup = target.substring(target.indexOf('.') + 1);

            if (['makeMoreFlexible', 'toneUp'].includes(targetGroup)) {

                return I18n.t(`profile.objectivesDescriptions.${ targetGroup }.label`) + ' - ' + I18n.t(`profile.objectivesDescriptions.${ targetGroup }.childs.${ targetSubGroup }`);

            } else {

                if (targetSubGroup.includes('.')) {

                    const injuryType = targetSubGroup.substring(0, targetSubGroup.indexOf('.'));
                    let injurySubtype = targetSubGroup.substring(targetSubGroup.indexOf('.') + 1);
                    return I18n.t('profile.objectivesDescriptions.improveComplaints.label') + I18n.t(`profile.injuriesDescriptions.${ targetGroup }.label`) + ' - ' + I18n.t(`profile.injuriesDescriptions.${ targetGroup }.childs.${ injuryType }.label`) + ' - ' + I18n.t(`profile.injuriesDescriptions.${ targetGroup }.childs.${ injuryType }.childs.${ injurySubtype }`);

                } else {

                    return I18n.t('profile.objectivesDescriptions.improveComplaints.label') + I18n.t(`profile.injuriesDescriptions.${ targetGroup }.label`) + ' - ' + I18n.t(`profile.injuriesDescriptions.${ targetGroup }.childs.${ targetSubGroup }.label`);

                }

            }

        } else {

            return target;

        }

    }

    render() {

        const { program, sequences } = this.props;
        const { activeVideoPresentation } = this.state;

        return !_.isEmpty(program) && (

            <React.Fragment>
                <GradientNav active='practice' { ...this.props } />
                <Wrapper>
                    <TopBar { ...this.props } callback={ () => this.props.history.push('/practiceList') } text={ program.title['ES'] } />
                    <FixedBanner />
                    <InnerWrapper style={{maxWidth: 1240, paddingTop: '3rem'}}>
                        <section>
                            <div className='i-back' style={{ padding: '15px 15px 30px 0' }} onClick={ () => this.props.history.push('/practiceList') }>
                                <Icon name='angle left' color='black'/>
                                <strong>{ I18n.t('actions.back') }</strong>
                            </div>
                        </section>
                        <section className='content-section'>
                            <div className='background-wrapper'>
                                <Image src={ program.imageUrl } />
                            </div>
                            { (activeVideoPresentation && program.videoUrl?.length > 0) &&
                                <div className='preview-iframe'>
                                    <Icon name='close' className='close-preview' size='big' onClickCapture={ this.onTogglePreview }></Icon>
                                    <div className='preview-expanded-16-9' >
                                        <iframe title='vimeo' src={ Config.player.path + window.btoa(program.videoUrl) } width='100%' height='100%' frameBorder='0' allow='autoplay; fullscreen' allowFullScreen></iframe>
                                    </div>
                                </div>
                            }
                            <div className='content-section-wrapper'>
                                <div className='master-crump'>
                                    <strong className='display-subtitle-4'>{ I18n.t('practice.practice') } / { program.title['ES'] }</strong>
                                </div>
                                <div>
                                    <h1>
                                        { program.title['ES'] }
                                    </h1>
                                </div>
                                <div className='master-ctas'>
                                    <Responsive minWidth='720' as={React.Fragment}>
                                        <OButton size='huge' primary type='button' color='#fff' onClickCapture={ this.practiceNow }>
                                            <span>{ I18n.t('actions.startSequence') }</span>
                                        </OButton>
                                        <OButton size='huge' terciary type='button' color='#30D0B0' onClick={ this.onTogglePreview } style={{ margin: '0 0 0 15px' }}>
                                            <span>{ I18n.t('actions.startPreview') }</span>
                                        </OButton>
                                    </Responsive>
                                    <Responsive maxWidth='719' as={React.Fragment}>
                                        <OButton fluid size='large' primary type='button' color='#fff' style={{ margin: '0 0 10px 0' }} onClickCapture={ this.practiceNow }>
                                            <span>{ I18n.t('actions.startSequence') }</span>
                                        </OButton>
                                        <OButton fluid size='large' terciary type='button' color='#30D0B0' onClick={ this.onTogglePreview } >
                                            <span>{ I18n.t('actions.startPreview') }</span>
                                        </OButton>
                                    </Responsive>
                                </div>
                                <div className='master-description'>
                                    <p className='display-subtitle-2'>{ I18n.t('practice.practiceDescription') }</p>
                                    <p className='display-subtitle-1' dangerouslySetInnerHTML={{ __html: program.description['ES'] }}></p>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className='master-related'>
                                <p className='display-subtitle-2'>{ I18n.t('practice.relatedContent') }</p>
                                <div className='related-items'>
                                    { this.setMetaItems(program.types, program.level) }
                                </div>
                            </div>
                        </section>
                        <section>
                            { _.isEmpty(program.targets) ? '' :
                                <div className='master-targets'>
                                    <p className='display-subtitle-2'>{ I18n.t('practice.sequenceTargets') }</p>
                                    <div className='targets-wrapper'>
                                        { (program.targets || []).map((target) => (
                                            <p>{ this.getTargetDescription(target) }.&nbsp;</p>
                                        )) }
                                    </div>
                                </div>
                            }
                        </section>
                        <section className="master-grid-wrapper">
                            <p className="display-title">{I18n.t('practice.programSequences')}</p>
                            <div className="master-grid">
                                {
                                    _.map(_.orderBy(sequences, ['order'], ['asc']), (sequence, index) => (
                                        <div className="master-grid-item" onClickCapture={(e)=> this.practiceNow(sequence._id)} key={sequence._id}>
                                            <div className="item-number">
                                                <p className="display-1">{index + 1}</p>
                                            </div>
                                            <div className="item-cover"><img src={sequence.thumbUrl} alt="" /></div>
                                            <div className="item-content">
                                                <div className="display-subtitle-3 item-title" dangerouslySetInnerHTML={{ __html: sequence.title['ES'] }}></div>
                                                <div className="display-subtitle-4" dangerouslySetInnerHTML={{ __html: sequence.description['ES'] }}></div>
                                                <OButton upper size="small" terciary type="button" onClickCapture={(e)=>{ this.onMoreInfo(e,sequence._id)}} style={{margin: "20px 0"}}>
                                                    <Icon name="plus"></Icon> <span>{'Más info'}</span>
                                            </OButton>
                                            </div>
                                        </div>

                                    ))
                                }
                                    
                            </div>
                        </section>
                    </InnerWrapper>
                    <div className='footer-spacer'></div>
                    <Footer { ...this.props } type='branded' style={{ zIndex: 2 }}/>
                </Wrapper>
                <SubMenu active='practice' { ...this.props }/>
            </React.Fragment>
        )
    }
}